<template>
  <div class="advanced-table">
    <DataTable
      :tableData="locations"
      :headers="headers"
      :loading="showSkeleton"
      :heading="heading"
      :perPage="10"
      :selectedRows.sync="selectedRows"
      ref="dataTable"
      searchable
    >
      <template v-slot:verified="props">
        {{ props.row.verified }}
      </template>
      <template v-slot:optIn="props">
        {{ props.row.optIn ? 'Yes' : 'No' }}
      </template>
      <template v-slot:expand="props">
        <DataTable :tableData="props.row.nestedData" :headers="nestedHeaders" :loading="showSkeleton" />
      </template>
      <template v-slot:columnsAfter>
        <PassportTableColumn width="275px" label="Actions">
          <template v-slot="props">
            <div class="action-row">
              <PassportButton
                class="text--table-action"
                label="Edit"
                :to="`/locations/edit/${props.row.id}`"
                variant="text"
              />
              <div class="g-divider vertical" />
              <PassportButton
                class="text--table-action"
                label="Duplicate"
                variant="text"
                @click="duplicateRow(props.row)"
              />
              <div class="g-divider vertical" />
              <PassportButton
                class="text--table-action"
                label="Delete"
                variant="text danger"
                @click="deleteRow(props.row)"
              />
            </div>
          </template>
        </PassportTableColumn>
      </template>
    </DataTable>
    <ModalDelete
      ref="deleteModal"
      :deleteCount="selectedRows.length"
      :icon="() => import('@/assets/icons/nav_assets_v2.svg')"
      @cancel="selectedRows = []"
    />
  </div>
</template>

<script>
import DataTable from '@/components/DataTable.vue';
import PassportTableColumn from '@/components/PassportTableColumn.vue';
import PassportButton from '@/components/PassportButton.vue';
import ModalDelete from '@/components/ModalDelete.vue';
import { v4 as uuidv4 } from 'uuid';

export default {
  name: 'AdvancedTableExample',
  components: {
    DataTable,
    PassportTableColumn,
    PassportButton,
    ModalDelete,
  },
  props: { heading: String },
  data() {
    return {
      locations: [],
      showSkeleton: true,
      selectedRows: [],
      headers: {
        username: {
          label: 'Username',
          sortable: true,
          searchable: true,
          minWidth: '10%',
        },
        email: {
          label: 'Email',
          sortable: true,
          searchable: true,
          minWidth: '10%',
        },
        verified: {
          label: 'Verified',
          sortable: true,
          minWidth: '10%',
        },
        points: {
          label: 'Points',
          sortable: true,
          searchable: true,
          minWidth: '10%',
        },
        targets: {
          label: 'Targets',
          minWidth: '5%',
          colspan: 2,
        },
        expand: {
          type: 'expand',
          width: '20',
        },
        optIn: {
          label: 'Opt-In',
          sortable: true,
          minWidth: '10%',
        },
      },
      nestedHeaders: {
        field1: {
          label: 'Field 1',
        },
        field2: {
          label: 'Field 2',
        },
        field3: {
          label: 'Field 3',
        },
        field4: {
          label: 'Field 4',
        },
      },
    };
  },
  created() {
    this.getLocations().finally(() => {
      this.showSkeleton = false;
    });
  },
  methods: {
    getLocations() {
      return this.getFakeLocations();
      // What the real call might look like
      // return this.$store
      //   .dispatch('getLocations')
      //   .then(({ data }) => {
      //     const { locations } = data.data;
      //     locations.forEach((location) => {
      //       location.checked = false;
      //     });
      //     this.locations = locations;
      //   })
      //   .catch((err) => {
      //     this.$notify({
      //       group: 'primary',
      //       type: 'error',
      //       text: `There was an error getting locations: ${err.message}`,
      //     });
      //   });
    },
    getFakeLocations() {
      return new Promise((resolve) => {
        this.locations = [];
        for (let i = 0; i < 19; i += 1) {
          this.locations.push({
            id: uuidv4(),
            checked: false,
            username: 'Lorem ipsum',
            email: '12345 Street Name, City, State Zip',
            verified: true,
            points: 30000 + i,
            optIn: true,
            targets: 4,
            nestedData: [
              {
                id: uuidv4(),
                checked: false,
                field1: 'Lorem ipsum',
                field2: 'Lorem ipsum',
                field3: 'Lorem ipsum',
                field4: 'Lorem ipsum',
              },
              {
                id: uuidv4(),
                checked: false,
                field1: 'Lorem ipsum',
                field2: 'Lorem ipsum',
                field3: 'Lorem ipsum',
                field4: 'Lorem ipsum',
              },
              {
                id: uuidv4(),
                checked: false,
                field1: 'Lorem ipsum',
                field2: 'Lorem ipsum',
                field3: 'Lorem ipsum',
                field4: 'Lorem ipsum',
              },
              {
                id: uuidv4(),
                checked: false,
                field1: 'Lorem ipsum',
                field2: 'Lorem ipsum',
                field3: 'Lorem ipsum',
                field4: 'Lorem ipsum',
              },
            ],
          });
        }
        setTimeout(() => {
          resolve();
        }, 0);
      });
    },
    deleteRow(row) {
      this.selectedRows = [{ ...row, checked: true }];
      this.deleteRows(this.selectedRows);
    },
    async deleteRows(rows) {
      // if (!rows.length) return;
      if (!(await this.$refs.deleteModal.confirm())) return;
      // fake delete
      rows.forEach(({ id }) => {
        const index = this.locations.findIndex((location) => location.id === id);
        if (index > -1) this.locations.splice(index, 1);
      });
      // What real delete might look like. Should map rows to just be an array of id's I think
      // this.$store
      //   .dispatch('deleteLocations', rows)
      //   .then(() => {
      //     this.closeModal();
      //     this.$notify({
      //       group: 'primary',
      //       type: 'success',
      //       text: 'Your asset(s) were successfully deleted.',
      //     });
      //     this.getLocations();
      //   })
      //   .catch((err) => {
      //     this.$notify({
      //       group: 'primary',
      //       type: 'error',
      //       text: `There was an error deleting asset(s): ${err.message}`,
      //     });
      //   });
    },
    duplicateRow(row) {
      return row;
    },
  },
};
</script>

<style lang="scss" scoped>
.advanced-table {
  display: flex;
  flex-direction: column;
  width: 100%;

  .actions {
    align-self: flex-end;
  }

  ::v-deep .passport-button.default.danger {
    font-size: 14px;
    text-transform: uppercase;
  }
}
</style>
