<template>
  <div class="examples">
    <GCollapsible class="example" title="Filter Popover">
      <TableFilter @reset="clearFilters" @apply="applyFilters" :badgeCount="filterCount">
        <template slot="body" class="body">
          <h4>Filter Targets:</h4>
          <h5 class="text--input-header">Target Types</h5>
          <div class="types">
            <PassportCheckbox v-model="filters.types.text" label="Text Only" />
            <PassportCheckbox v-model="filters.types.image" label="Image" />
            <PassportCheckbox v-model="filters.types.video" label="Video" />
            <PassportCheckbox v-model="filters.types.quiz" label="Personality Quiz" />
            <PassportCheckbox v-model="filters.types.link" label="Link" />
            <PassportCheckbox v-model="filters.types.trivia" label="Trivia" />
          </div>
          <h5 class="text--input-header">Group</h5>
          <PassportDropdown v-model="filters.selectedGroup" :options="groupOptions" placeholder="Select Group" />
        </template>
      </TableFilter>
    </GCollapsible>
    <GCollapsible class="example" title="Button Examples">
      <h2>Default</h2>
      <PassportButton to="/examples" label="Primary" />
      <PassportButton variant="secondary" label="Secondary" />
      <PassportButton variant="danger" label="Danger" />
      <h2>Outlined</h2>
      <PassportButton variant="outline" to="/examples" label="Primary" />
      <PassportButton variant="outline secondary" label="Secondary" />
      <PassportButton variant="outline danger" label="Danger" />
      <h2>Text</h2>
      <PassportButton variant="text" to="/examples" label="Primary" />
      <PassportButton variant="text secondary" label="Secondary" />
      <PassportButton variant="text danger" label="Danger" />
    </GCollapsible>
    <GCollapsible class="example" title="Input Examples">
      <h2>Input</h2>
      <PassportInput placeholder="Input" />
      <h2>Textarea</h2>
      <PassportInput type="textarea" />
    </GCollapsible>
    <GCollapsible class="example" title="Action Examples">
      <h2>Radio List</h2>
      <PassportRadio value="yes" v-model="exRadioList" />
      <PassportRadio value="no" v-model="exRadioList" />
      <h2>Checkbox</h2>
      <PassportCheckbox v-model="exChecked" label="Checkbox" />
      <h2>Switch</h2>
      <PassportSwitch v-model="exSwitch" label="Switch" />
    </GCollapsible>
    <GCollapsible class="example" title="Dropdown Example">
      <PassportDropdown :options="options" v-model="selectedOption" trackBy="type" label="label" />
    </GCollapsible>
    <GCollapsible class="example" title="Basic Table Example">
      <BasicTableExample heading="stuff" />
    </GCollapsible>
    <GCollapsible class="example" title="Nested Table Example">
      <AdvancedTableExample heading="stuff" />
    </GCollapsible>
    <GCollapsible class="example" title="Trivia Panel Example">
      <form @submit.prevent="submitForm">
        <TriviaQuestionPanel ref="questions" :existingQuestions.sync="existingQuestions" />
        <PassportButton type="submit" label="validate" @click="validateForm" />
      </form>
    </GCollapsible>
    <GCollapsible class="example" title="Uploader Examples">
      <h2>All files uploader</h2>
      <PassportImageUploader
        ref="file1"
        class="image-upload"
        key="instance-0"
        :imageWidth="350"
        :imageHeight="200"
        :maxFileSize="40000000"
        :presignedUrlParams="{ type: 'book', item: 'cover' }"
        @fileAdded="fileAddedAction($event)"
        @fileRemoved="fileRemovedAction"
      />
      <h2>Image Uploader w/ cropper</h2>
      <form @submit.prevent="submitFile">
        <PassportImageUploader
          ref="stamp"
          class="image-upload"
          key="instance-1"
          :imageWidth="150"
          :imageHeight="200"
          :allowedFileTypes="['image/png', 'image/jpeg']"
          :maxFileSize="40000000"
          :presignedUrlParams="{ type: 'book', item: 'cover' }"
          showCropper
          @fileAdded="fileAddedAction($event)"
          @fileRemoved="fileRemovedAction"
        />
        <PassportButton label="submit" type="submit" @click="submitFile" />
      </form>
    </GCollapsible>
    <GCollapsible class="flyout example" title="Flyout Examples">
      <PassportButton label="Show Success Flyout" variant="success" @click="showNotification('success')" />
      <PassportButton label="Show Error Flyout" variant="danger" @click="showNotification('error')" />
      <PassportButton label="Show Text Only Flyout" @click="showNotification('text')" />
      <PassportButton label="Clear Notifications" variant="text danger" @click="clearNotifications" />
    </GCollapsible>
    <GCollapsible class="example" title="Datepicker Example">
      <div class="date-range">
        <PassportDatepicker
          v-model="exampleDate.start"
          :max-date="exampleDate.end || new Date()"
          placeholder="Start Date"
        />
        <div class="separator">To</div>
        <PassportDatepicker
          v-model="exampleDate.end"
          :min-date="exampleDate.start || undefined"
          :max-date="new Date()"
          placeholder="End Date"
        />
      </div>
      {{ exampleDate }}
    </GCollapsible>
  </div>
</template>

<script>
import { GCollapsible } from '@twentyfourg/grimoire';
import PassportDatepicker from '@/components/PassportDatepicker.vue';
import PassportButton from '@/components/PassportButton.vue';
import PassportInput from '@/components/PassportInput.vue';
import PassportRadio from '@/components/PassportRadio.vue';
import PassportCheckbox from '@/components/PassportCheckbox.vue';
import PassportSwitch from '@/components/PassportSwitch.vue';
import PassportDropdown from '@/components/PassportDropdown.vue';
import BasicTableExample from '@/components/Examples/BasicTableExample.vue';
import AdvancedTableExample from '@/components/Examples/AdvancedTableExample.vue';
import TriviaQuestionPanel from '@/components/TriviaQuestionPanel.vue';
import PassportImageUploader from '@/components/PassportImageUploader.vue';
import TableFilter from '@/components/TableFilter.vue';

export default {
  name: 'default',
  components: {
    GCollapsible,
    PassportButton,
    PassportInput,
    PassportRadio,
    PassportCheckbox,
    PassportSwitch,
    PassportDropdown,
    PassportDatepicker,
    BasicTableExample,
    AdvancedTableExample,
    TriviaQuestionPanel,
    PassportImageUploader,
    TableFilter,
  },
  props: {},
  data() {
    return {
      exRadioList: null,
      exChecked: false,
      exSwitch: false,
      selectedOption: null,
      filters: {
        types: {
          text: false,
          image: false,
          video: false,
          quiz: false,
          link: false,
          trivia: false,
        },
        selectedGroup: null,
      },
      groupOptions: ['Group 1', 'Group 2', 'Group 3', 'Group 4', 'Group 5', 'Group 6'],
      options: [
        {
          label: 'Text only',
          type: 'text',
        },
        {
          label: 'Image',
          type: 'image',
        },
        {
          label: 'Video',
          type: 'video',
        },
        {
          label: 'Link',
          type: 'link',
        },
        {
          label: 'Personality Quiz',
          type: 'quiz',
        },
        {
          label: 'Trivia',
          type: 'trivia',
        },
      ],
      existingQuestions: [
        {
          id: '470cd5e5-8564-4f9f-8234-18a8c3c5cbe8',
          questionId: '470cd5e5-8564-4f9f-8234-18a8c3c5cbe8',
          questionTypeId: 'c99ffaf4-43c8-4c2e-b1aa-7b2f0706939a',
          text: '???',
          order: 1,
          updatedAt: '2022-05-12T20:07:15.000Z',
          createdAt: '2022-05-12T20:07:15.000Z',
          questionOptions: [],
          questionAnswers: [
            {
              id: '33fce444-7237-475e-ad45-c3df0276c325',
              questionId: '470cd5e5-8564-4f9f-8234-18a8c3c5cbe8',
              text: '123',
              matchOptionId: null,
              updatedAt: '2022-05-12T20:07:15.000Z',
              createdAt: '2022-05-12T20:07:15.000Z',
            },
          ],
          type: {
            id: 'c99ffaf4-43c8-4c2e-b1aa-7b2f0706939a',
            label: 'Open Answer',
            key: 'open answer',
            updatedAt: '2021-09-28T16:31:43.000Z',
            createdAt: '2021-09-28T16:31:43.000Z',
          },
        },
      ],
      exampleDate: {
        start: null,
        end: null,
      },
    };
  },
  created() {},
  computed: {
    filterCount() {
      let count = 0;
      Object.values(this.filters.types).forEach((type) => {
        if (type) count += 1;
      });
      if (this.filters.selectedGroup) count += 1;
      return count;
    },
  },
  watch: {},
  methods: {
    validateForm() {
      this.$refs.questions.validateQuiz();
    },
    submitForm() {
      // This should not be called if form is invalid?
      // console.log('submitted!');
    },
    fileRemovedAction() {},
    fileAddedAction() {},
    submitFile() {
      if (this.$refs.stamp.hasFile) this.$refs.stamp.uploadFileToS3();
    },
    applyFilters() {},
    clearFilters() {
      Object.keys(this.filters.types).forEach((key) => {
        this.filters.types[key] = false;
      });
      this.filters.selectedGroup = null;
    },
    clearNotifications() {
      this.$notify({
        group: 'primary',
        clean: true,
      });
    },
    showNotification(type) {
      this.$notify({
        group: 'primary',
        type,
        text: `This is an example of a "${type}" flyout notification!`,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.examples {
  .example {
    padding: 10px;
    margin-bottom: 10px;
    border: 2px solid white;
    border-radius: 10px;

    ::v-deep {
      .header {
        padding: 10px;
      }
      .collapse-wrapper > .content {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
      }
    }
    &.flyout {
      .passport-button {
        margin-bottom: 10px;
        &.default.success {
          background-color: rgb(39, 158, 39);
          &:not(&:disabled) {
            &:hover,
            &:focus {
              background-color: darken(rgb(39, 158, 39), 5%);
            }
          }
        }
      }
    }
    form {
      width: 100%;
    }
    .image-upload {
      max-width: 500px;
    }
    .filter {
      h5 {
        margin: 0;
        margin-bottom: 8px;
      }
      .types {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 10px 15px;
        margin-bottom: 40px;
      }
      .passport-checkbox {
        flex-shrink: 0;
        color: black;
        ::v-deep {
          .checkbox {
            width: 20px;
            height: 20px;
          }
          .label {
            white-space: nowrap;
          }
        }
      }
    }
    .date-range {
      display: flex;
      align-items: center;
      .separator {
        margin: 25px;
        text-transform: uppercase;
      }
    }
  }
}
</style>
