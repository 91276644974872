<template>
  <div class="basic-table">
    <div class="actions">
      <PassportButton
        variant="danger"
        label="Delete Selected Rows"
        :disabled="selectedRows.length <= 0"
        @click="deleteRows(selectedRows)"
      />
      <PassportButton to="/examples" label="Download as .csv" />
    </div>
    <span class="page-heading"> {{ heading }} </span>
    <hr class="line-break" />
    <DataTable
      :tableData="users"
      :headers="headers"
      :loading="showSkeleton"
      :heading="heading.toLowerCase()"
      :perPage="10"
      :selectedRows.sync="selectedRows"
      ref="dataTable"
      searchable
    >
      <template v-slot:verified="props">
        {{ props.row.verified }}
      </template>
      <template v-slot:optIn="props">
        {{ props.row.optIn ? 'Yes' : 'No' }}
      </template>
      <template v-slot:expand="props">
        <DataTable :tableData="props.row.nestedData" :headers="nestedHeaders" :loading="showSkeleton" />
      </template>
    </DataTable>
    <ModalDelete
      ref="deleteModal"
      :deleteCount="selectedRows.length"
      :icon="() => import('@/assets/icons/nav_assets_v2.svg')"
      @cancel="selectedRows = []"
    />
  </div>
</template>

<script>
import DataTable from '@/components/DataTable.vue';
import PassportButton from '@/components/PassportButton.vue';
import ModalDelete from '@/components/ModalDelete.vue';
import { v4 as uuidv4 } from 'uuid';

export default {
  name: 'BasicTableExample',
  components: {
    DataTable,
    PassportButton,
    ModalDelete,
  },
  props: {
    heading: String,
  },
  data() {
    return {
      users: [],
      showSkeleton: true,
      selectedRows: [],
      headers: {
        username: {
          label: 'Username',
          sortable: true,
          searchable: true,
          minWidth: '30%',
        },
        email: {
          label: 'Email',
          sortable: true,
          searchable: true,
          minWidth: '30%',
        },
        verified: {
          label: 'Verified',
          sortable: true,
          minWidth: '10%',
        },
        points: {
          label: 'Points',
          sortable: true,
          searchable: true,
          minWidth: '10%',
        },
        optIn: {
          label: 'Opt-In',
          sortable: true,
          minWidth: '10%',
        },
      },
    };
  },
  created() {
    this.getUsers().finally(() => {
      this.showSkeleton = false;
    });
  },
  methods: {
    getUsers() {
      return this.getFakeUsers();
      // What the real call might look like
      // return this.$store
      //   .dispatch('getUsers')
      //   .then(({ data }) => {
      //     const { users } = data.data;
      //     users.forEach((location) => {
      //       location.checked = false;
      //     });
      //     this.users = users;
      //   })
      //   .catch((err) => {
      //     this.$notify({
      //       group: 'primary',
      //       type: 'error',
      //       text: `There was an error getting users: ${err.message}`,
      //     });
      //   });
    },
    getFakeUsers() {
      return new Promise((resolve) => {
        this.users = [];
        for (let i = 0; i < 19; i += 1) {
          this.users.push({
            id: uuidv4(),
            checked: false,
            username: 'Lorem ipsum',
            email: '12345 Street Name, City, State Zip',
            verified: true,
            points: 30000 + i,
            optIn: true,
          });
        }
        setTimeout(() => {
          resolve();
        }, 0);
      });
    },
    deleteRow(row) {
      this.selectedRows = [{ ...row, checked: true }];
      this.deleteRows(this.selectedRows);
    },
    async deleteRows(rows) {
      // if (!rows.length) return;
      if (!(await this.$refs.deleteModal.confirm())) return;
      // fake delete
      rows.forEach(({ id }) => {
        const index = this.users.findIndex((row) => row.id === id);
        if (index > -1) this.users.splice(index, 1);
      });
      // What real delete might look like. Should map rows to just be an array of id's I think
      // this.$store
      //   .dispatch('deleteusers', rows)
      //   .then(() => {
      //     this.closeModal();
      //     this.$notify({
      //       group: 'primary',
      //       type: 'success',
      //       text: 'Your asset(s) were successfully deleted.',
      //     });
      //     this.getUsers();
      //   })
      //   .catch((err) => {
      //     this.$notify({
      //       group: 'primary',
      //       type: 'error',
      //       text: `There was an error deleting asset(s): ${err.message}`,
      //     });
      //   });
    },
    duplicateRow(row) {
      return row;
    },
  },
};
</script>

<style lang="scss" scoped>
.basic-table {
  display: flex;
  flex-direction: column;
  width: 100%;

  .actions {
    align-self: flex-end;
  }

  .line-break {
    width: 1601px;
    margin-top: 3em;
    margin-bottom: 3em;
    opacity: 0.1;
  }
  .page-heading {
    display: flex;
    font-size: 28px;
  }
}
</style>
